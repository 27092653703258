
import "./tracking_settings";
$(window).on('load', function() {
        if (frappe.session.user != "Guest") {
            _mtm.push({'uid': frappe.session.user});
        }
    (function () {
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.async = true; g.src = 'https://werner.solutions/mat/js/container_FEwZLx89.js'; s.parentNode.insertBefore(g, s);
    })();
});
